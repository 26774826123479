class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {

				}
			},
			methods: {
				goBack() {
					this.$router.go(-1);
				},
				setContentHeight() {
					//console.log({'route': this.$route});
					let element = document.querySelector('.height-fix');
					if (element) {
						let bodyRect = document.body.getBoundingClientRect();
						if (bodyRect) {
							let elemRect = element.getBoundingClientRect();
							let offset = elemRect.top - bodyRect.top;
							element.style.cssText = `
								height:${window.innerHeight - (offset + 12)}px;
								overflow:auto;
							`;
						}
					}
				}
			},
			mounted() {
				const _this = this;
				setTimeout(() => {
					_this.setContentHeight();
				}, 100)
			},
			// errorCaptured(err, vm, info) {
			// 	this.logError({ err, vm, info });
			// },
		})
	}
}
export default new MainSetting();