import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        component: () => import("@/views/layout/Layout.vue"),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@/views/pages/Dashboard.vue')
            },
            {
                path: 'payment-counter',
                name: 'payment-counter',
                component: () => import('@/views/pages/screens/PaymentCounter.vue')
            },
            {
                path: 'search-vehicle',
                name: 'search-vehicle',
                component: () => import('@/views/pages/screens/SearchVehicle.vue')
            },
            {
                path: 'detail-vehicle/:id',
                name: 'detail-vehicle',
                component: () => import('@/views/pages/screens/vehicleDetail.vue')
            },
            {
                path: 'select-discount',
                name: 'select-discount',
                component: () => import('@/views/pages/screens/SelectDiscount.vue')
            },
            {
                path: 'payment-selection',
                name: 'payment-selection',
                component: () => import('@/views/pages/screens/PaymentSelect.vue')
            },
            {
                path: 'payment-confirm',
                name: 'payment-confirm',
                component: () => import('@/views/pages/screens/PaymentConfirm.vue')
            },
            {
                path: 'payment-qr',
                name: 'payment-qr',
                component: () => import('@/views/pages/screens/QRpayPage.vue')
            },
            {
                path: 'transaction-decline',
                name: 'transaction-decline',
                component: () => import('@/views/pages/screens/TransactionDecline.vue')
            },
            {
                path: 'success-page',
                name: 'success-page',
                component: () => import('@/views/pages/screens/SuccessPage.vue')
            },
        ]
    },
    {
        path: '/dashboard',
        name: 'dashboard-alt',
        component: () => import('@/views/pages/Dashboard.vue')
    },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('@/views/pages/auth/Login.vue')
    // },
    {
        path: "*",
        redirect: "/404",
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/pages/error/Error.vue"),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
